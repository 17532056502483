export const GET_ZONES_REQUEST = 'zones/GET_ZONES_REQUEST';
export const GET_ZONES_SUCCESS = 'zones/GET_ZONES_SUCCESS';
export const GET_ZONES_FAILURE = 'zones/GET_ZONES_FAILURE';

export const ADD_ZONES_REQUEST = 'zones/ADD_ZONES_REQUEST';
export const ADD_ZONES_SUCCESS = 'zones/ADD_ZONES_SUCCESS';
export const ADD_ZONES_FAILURE = 'zones/ADD_ZONES_FAILURE';

export const DEL_ZONES_REQUEST = 'zones/DEL_ZONES_REQUEST';
export const DEL_ZONES_SUCCESS = 'zones/DEL_ZONES_SUCCESS';
export const DEL_ZONES_FAILURE = 'zones/DEL_ZONES_FAILURE';

export const MODIFY_ZONES_REQUEST = 'zones/MODIFY_ZONES_REQUEST';
export const MODIFY_ZONES_SUCCESS = 'zones/MODIFY_ZONES_SUCCESS';
export const MODIFY_ZONES_FAILURE = 'zones/MODIFY_ZONES_FAILURE';

export const HANDLE_ADD_ZONE_MODAL_SHOW = 'zones/HANDLE_ADD_ZONE_MODAL_SHOW';
export const HANDLE_ADD_ZONE_MODAL_CANCEL = 'zones/HANDLE_ADD_ZONE_MODAL_CANCEL';

export const HANDLE_MODIFY_ZONE_MODAL_SHOW = 'zones/HANDLE_MODIFY_ZONE_MODAL_SHOW';
export const HANDLE_MODIFY_ZONE_MODAL_CANCEL = 'zones/HANDLE_MODIFY_ZONE_MODAL_CANCEL';

export const ON_CHANGE_TITLE = 'zones/ON_CHANGE_TITLE';
export const ON_CHANGE_DESCRIPTION = 'zones/ON_CHANGE_DESCRIPTION';
export const ON_CHANGE_POINTS = 'users/ON_CHANGE_POINTS';
